<template>
  <div class="container-lg clearfix">
    <div class="d-none d-lg-block ck-content" v-html="content.desktop"></div>
    <div class="d-lg-none ck-content" v-html="content.mobile"></div>
  </div>
</template>
<script>
import { toaster } from '@/_helpers';

export default {
  name: 'BrandContent',
  data() {
    return {
      content: {},
    }
  },
  computed: {
    param() {
      return this.$route.params.param;
    },
    paramId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    const conditions = {
      brand: (id) => this.getBrandContent(id),
      collection: (id) => this.getCollectionContent(id),
    }
    conditions[this.param](this.paramId)
  },
  methods: {
    async getBrandContent(id) {
      try {
        const res = await this.$api.cms.getBrands(id)
        if (res.status === 200) {
          const { data } = res.data
          this.content = {
            desktop: data.content,
            mobile: data.content_mobile,
          }
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async getCollectionContent(id) {
      try {
        const res = await this.$api.cms.getCollections(id)
        if (res.status === 200) {
          const { data } = res.data
          this.content = {
            desktop: data.content,
            mobile: data.content_mobile,
          }
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    }
  }
}
</script>
